import React from 'react';
import Pellet from '../components/Pellet/Pellet';

const PelletPage: React.FC = () => {
    return (
        <div className='PelletPage'>
            <Pellet />
        </div>
    );
};

export default PelletPage;
