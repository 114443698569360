import React, { useEffect, useState } from 'react';
import './Header.css';
import cc2 from '../../assets/images/cc2.jpg';
import fsc from '../../assets/images/fsc.jpg';
import { LuArrowUpRight } from "react-icons/lu";
import { useTranslation } from 'react-i18next';

// CONTACT BUTTONS (circular text buttons)
const RotatedText: React.FC<{ text: string }> = ({ text }) => {
    return (
        <p>
            {text.split('').map((character, index) => (
                <span key={index} style={{ transform: `rotate(${index * 12.55}deg)` }}>
                    {character}
                </span>
            ))}
        </p>
    );
};

const Header: React.FC = () => {

    const { t } = useTranslation();

    // CONTACT BUTTONS (circular text buttons)
    const [, setText] = useState('');

    useEffect(() => {
        const textButtons = document.querySelectorAll<HTMLAnchorElement>('.contact__btn');

        textButtons.forEach(textButton => {
            const p = textButton.querySelector<HTMLParagraphElement>('p');
            if (p) {
                setText(p.innerText);
            }
        });
    }, []);

    return (
        <>
            <header>
                <div className="container header__container">
                    <div className="header__left">
                        <div className="header__head">
                            <span className="header__tag">#FSCcertified2024</span>
                        </div>
                        <h1>{t('header_title')}</h1>
                        <p>{t('header_paragraph')}</p>
                        {/* <a href="mailto:support@egattor.com" className="header__btn-md"></a> */}
                    </div>
                    <div className="header__right">
                        <div className="videoFrame">
                            <iframe
                                src="https://drive.google.com/file/d/1k5FnNdyH7pn86VWhz87q6_iCma_cN_jS/preview"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                </div>
            </header>
            <div className="header__frames">
                <div className="header__frame">
                    <img src={cc2} alt="Header Frame One" />
                </div>
                <div className="header__frame">
                    <img src={fsc} alt="Header Frame Two" />
                </div>
            </div>
            <a href="#foot" className="contact__btn header__btn">
                <LuArrowUpRight className="contact__btn-icon" />
                <RotatedText text={t('header_contact_btn')} />
            </a>
        </>
    );
}

export default Header;
