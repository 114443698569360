import React from "react";
import './AboutUs.css';
import img143 from '../../assets/images/143.jpg';
import { useTranslation } from "react-i18next";

const AboutUs: React.FC = () => {

    const { t } = useTranslation();

    return (
        <section id="exhibitions">
            <div className="container gallery__container swiper mySwiper">
                <div className="gallery__head">
                    <h2 className="gallery__title">{t('about_us_title')}</h2>
                </div>
                <div className="aboutGrid">
                    <div className="aboutTxt">
                        <p>{t('about_us_paragraph_1')} <br />{t('about_us_paragraph_2')}</p>
                    </div>
                    <article>
                        <img src={img143} alt="About Us" />
                    </article>
                </div>
                <div style={{ display: 'none' }} className="swiper-wrapper">
                    <article className="swiper-slide"></article>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;