import React from 'react';
import Header from '../components/Header/Header';
import Products from '../components/Products/Products';
import Facilities from '../components/Facilities/Facilities';
import AboutUs from '../components/AboutUs/AboutUs';

const HomePage: React.FC = () => {
    return (
        <div className='HomePage'>
            <Header />
            <Products />
            <Facilities />
            <AboutUs />
        </div>
    );
};

export default HomePage;
