import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Nav from './components/Nav/Nav'
import Footer from './components/Footer/Footer'
import HomePage from './pages/HomePage';
import PelletPage from './pages/PelletPage';

const App: React.FC = () => {
  return (
    <Router>
      <Nav />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/pellet" element={<PelletPage />} />
        <Route path="/pellets" element={<PelletPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
