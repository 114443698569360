import React, { useEffect, useState } from "react";
import 'swiper/swiper-bundle.css';
import Swiper from "swiper";
import './Facilities.css'
import { LuArrowUpRight } from "react-icons/lu";
import img143 from '../../assets/images/143.jpg';
import imgIMG0903 from '../../assets/images/IMG_0903.jpg';
import img5 from '../../assets/images/5.png';
import img2 from '../../assets/images/2.jpg';
import imgev1 from '../../assets/images/ev1.jpg';
import imgMG0147 from '../../assets/images/MG_0147.jpg';
import img7 from '../../assets/images/7.png';
import { useTranslation } from "react-i18next";


// CONTACT BUTTONS (circular text buttons)
const RotatedText: React.FC<{ text: string }> = ({ text }) => {
    return (
        <p>
            {text.split('').map((character, index) => (
                <span key={index} style={{ transform: `rotate(${index * 12.55}deg)` }}>
                    {character}
                </span>
            ))}
        </p>
    );
};

const Facilities: React.FC = () => {

    // CONTACT BUTTONS (circular text buttons)
    const [, setText] = useState('');

    useEffect(() => {
        const textButtons = document.querySelectorAll<HTMLAnchorElement>('.contact__btn');

        textButtons.forEach(textButton => {
            const p = textButton.querySelector<HTMLParagraphElement>('p');
            if (p) {
                setText(p.innerText);
            }
        });

        // Inicializa Swiper
        new Swiper('.mySwiper', {
            slidesPerView: 1,
            spaceBetween: 20,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                599: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                },
                1023: {
                    slidesPerView: 3,
                    spaceBetween: 60,
                },
            },
        });

    }, []);

    const { t } = useTranslation();

    return (
        <section id="gallery">
            <div className="nada"><br /> </div>
            <div className="container exhibitions__container">
                <h2 className="exhibitions__title">{t('facilities_title')}</h2>
                <div className="exhibitions__head">
                    <p>{t('facilities_paragraph')}</p>
                    <a href="#foot" className="contact__btn exhibitions__btn">
                        <LuArrowUpRight className="contact__btn-icon" />
                        <RotatedText text={t('facilities_contact_btn')} />
                    </a>
                </div>
                <div className="exhibitions__gallery">
                    <article><img id="Facility_1" src={img143} alt="Facility 1" /></article>
                    <article><img src={img5} alt="Facility 3" /></article>
                    <article><img src={imgIMG0903} alt="Facility 2" /></article>
                    <article><img src={img2} alt="Facility 4" /></article>
                    <article><img src={imgev1} alt="Facility 5" /></article>
                    <article><img src={imgMG0147} alt="Facility 6" /></article>
                    <article><img src={img7} alt="Facility 7" /></article>
                </div>
            </div>
        </section>
    );
}

export default Facilities;