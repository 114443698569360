import React, { useEffect } from 'react';
import './Nav.css';
import logo from '../../assets/images/logo.png';
import { FaLinkedin } from "react-icons/fa";
import { IoMenu, IoClose } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

const Nav: React.FC = () => {

    useEffect(() => {
        const handleScroll = () => {
            const nav = document.querySelector('nav');
            if (nav) {
                nav.classList.toggle('window-scrolled', window.scrollY > 0);
            }
        };

        window.addEventListener('scroll', handleScroll);

        const nav = document.querySelector('.nav__links') as HTMLElement;
        const openNavBtn = document.querySelector('#nav__toggle-open') as HTMLElement;
        const closeNavBtn = document.querySelector('#nav__toggle-close') as HTMLElement;

        const openNav = () => {
            if (nav && openNavBtn && closeNavBtn) {
                nav.classList.remove('reverse');
                nav.style.display = 'flex';
                openNavBtn.style.display = 'none';
                closeNavBtn.style.display = 'inline-block';
            }
        };

        const closeNav = () => {
            if (nav && openNavBtn && closeNavBtn) {
                nav.classList.add('reverse');
                setTimeout(() => {
                    nav.style.display = 'none';
                }, 1100);
                openNavBtn.style.display = 'inline-block';
                closeNavBtn.style.display = 'none';
            }
        };

        if (openNavBtn) openNavBtn.addEventListener('click', openNav);
        if (closeNavBtn) closeNavBtn.addEventListener('click', closeNav);

        if (document.body.clientWidth < 1024 && nav) {
            nav.querySelectorAll('li a').forEach(navLink => {
                navLink.addEventListener('click', closeNav);
            })
        }
        closeNav(); //TODO: evitar que navbar se depliegue de manera natural
        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (openNavBtn) openNavBtn.removeEventListener('click', openNav);
            if (closeNavBtn) closeNavBtn.removeEventListener('click', closeNav);
        };
    }, []);

    //--------- i18n --------- //
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language;
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };


    return (
        <nav>
            <div className="container nav__container">
                <a href="/" className="nav__logo">
                    <div></div>
                    <img src={logo} alt="Nav Logo" />
                    <p className="flags">🇨🇱 🇨🇦</p>
                </a>
                <ul className="nav__links">
                    <li><a href="/#top">{t('nav_home')}</a></li>
                    <li><a href="/#about">{t('nav_products')}</a></li>
                    <li><a href="/pellet">{t('nav_pellet')}</a></li>
                    <li><a href="/#gallery">{t('nav_facilities')}</a></li>
                    <li><a href="/#exhibitions">{t('nav_about_us')}</a></li>
                </ul>
                <ul className="nav__socials">
                    {currentLanguage === 'en' ? (
                        <li><a href="#en" onClick={() => changeLanguage('es')}>🇪🇸</a></li>
                    ) : (
                        <li><a href="#es" onClick={() => changeLanguage('en')}>🇬🇧</a></li>
                    )}
                    <li><a href="https://www.linkedin.com/company/davidson-industry" target="_blank" rel='noreferrer'><FaLinkedin /></a></li>
                </ul>
                <button className="nav__toggle-btn" id="nav__toggle-open"><IoMenu /></button>
                <button className="nav__toggle-btn" id="nav__toggle-close"><IoClose /></button>
            </div>
        </nav>
    );
}

export default Nav;
