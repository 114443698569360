import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en/en_translation.json'
import translationES from './es/es_translation.json'

const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "es", // idioma por defecto
        interpolation: {
            escapeValue: false // react ya hace escape de los valores por defecto
        }
    });

export default i18n;