import React from 'react';
import './Products.css';
import about from '../../assets/images/about.png';
import door from '../../assets/images/door.png';
import board from '../../assets/images/board.png';
import windows from '../../assets/images/windows.png';
import pellet from '../../assets/images/pellet.jpg'
import { useTranslation } from 'react-i18next';

const Products: React.FC = () => {

    const { t } = useTranslation();

    return (
        <section id="about">
            <div className="container about__container">
                <h1 className="about__title" style={{
                    fontWeight: 'bold',
                    color: 'var(--color-white)'
                }}>{t('products_title_1')} <br /> {t('products_title_2')}</h1>
                <span className="">
                    {/* <i className="uil uil-arrow-up-right"></i>
                    <p>CONTACT - SEND ME AN EMAIL</p> */}
                </span>
                <div className="about__left">
                    <div className="about__image">
                        <div className="about__image-bg"></div>
                        <div className="about__image-lg">
                            <img src={about} alt="About" />
                        </div>
                        <div className="about__image-sm">
                            <img src={about} alt="About" />
                        </div>
                    </div>
                    <div className="product_Door_Components">
                        <h3>{t('products_DoorComponents_title')}</h3>
                        <article><img src={door} alt="Door Components" /></article>
                        <p>{t('products_DoorComponents_paragraph')}</p>
                    </div>
                    <div className="productDetail" id='tech_spec'>
                        <h3>{t('products_TechSpec_title')}</h3><br />
                        <div className="specs">
                            <div className="specsin"><h5>{t('products_TechSpec_h5_1')}</h5> <span>{t('products_TechSpec_span_1')}</span> </div>
                            <div className="specsin"><h5>{t('products_TechSpec_h5_2')}</h5> <span>{t('products_TechSpec_span_2')}</span> </div>
                            <div className="specsin"><h5>{t('products_TechSpec_h5_3')}</h5> <span>{t('products_TechSpec_span_3')}</span> </div>
                            <div className="specsin"><h5>{t('products_TechSpec_h5_4')}</h5> <span>{t('products_TechSpec_span_4')}</span> </div>
                            <div className="specsin"><h5>{t('products_TechSpec_h5_5')}</h5> <span>{t('products_TechSpec_span_5')}</span> </div>
                            <div className="specsin"><h5>{t('products_TechSpec_h5_6')}</h5> <span>{t('products_TechSpec_span_6')}</span> </div>
                        </div>
                    </div>
                    <div className="productDetail" id='opt_spec'>
                        <h3>{t('products_OptSpec_title')}</h3><br />
                        <div className="specs">
                            <div className="specsin"><h5>{t('products_OptSpec_h5_1')}</h5> <span>{t('products_OptSpec_span_1')}</span> </div>
                            <div className="specsin"><h5>{t('products_OptSpec_h5_2')}</h5> <span>{t('products_OptSpec_span_2')}</span> </div>
                            <div className="specsin"><h5>{t('products_OptSpec_h5_3')}</h5> <span>{t('products_OptSpec_span_3')}</span> </div>
                            <div className="specsin"><h5>{t('products_OptSpec_h5_4')}</h5> <span>{t('products_OptSpec_span_4')}</span> </div>
                            <div className="specsin"><h5>{t('products_OptSpec_h5_5')}</h5> <span>{t('products_OptSpec_span_5')}</span> </div>
                        </div>
                    </div>
                </div>
                <div className="about__right">
                    <div className="productDetail" id='shipping' style={{ marginTop: '130px' }}>
                        <h3>{t('products_shipping_title')}</h3><br />
                        <span>
                            {t('products_shipping_paragraph_1')} <hr /><br /> {t('products_shipping_paragraph_2')}
                        </span>
                    </div>
                    <div className="product_Trim_Boards">
                        <h3>{t('products_TrimBoards_title')}</h3>
                        <article><img src={board} alt="Trim Boards" /></article>
                        <p>{t('products_TrimBoards_paragraph')}</p>
                    </div>
                    <div className="product_Window_Components">
                        <h3>{t('products_WindowComponents_title')}</h3>
                        <article><img src={windows} alt="Window Components" /></article>
                        <p>{t('products_WindowComponents_paragraph')}</p>
                    </div>
                    <div className="product_pellet">
                        <a href="/pellet"><h3>{t('products_Pellet_title')}</h3></a>
                        <article><a href="/pellet"><img src={pellet} alt="Pellet" /></a></article>
                        <p>{t('products_Pellet_paragraph')}<a href="/pellet" id='product_Pellet_anchor'>{t('products_Pellet_anchor')}</a></p>
                    </div>
                </div>
            </div>

            {/*-----------LAYOUT RESPONSIVO-----------*/}

            <div className='res_layout'>
                <h1 className="about__title" style={{
                    fontWeight: 'bold',
                    color: 'var(--color-white)'
                }}>{t('products_title_1')} <br /> {t('products_title_2')}
                </h1>
                <div className="productDetail" id='shipping' style={{ marginTop: '130px' }}>
                    <h3>{t('products_shipping_title')}</h3><br />
                    <span>
                        {t('products_shipping_paragraph_1')} <hr /><br /> {t('products_shipping_paragraph_2')}
                    </span>
                </div>
                <div className="product_Door_Components">
                    <h3>{t('products_DoorComponents_title')}</h3>
                    <article><img src={door} alt="Door Components" /></article>
                    <p>{t('products_DoorComponents_paragraph')}</p>
                </div>
                <div className="product_Trim_Boards">
                    <h3>{t('products_TrimBoards_title')}</h3>
                    <article><img src={board} alt="Trim Boards" /></article>
                    <p>{t('products_TrimBoards_paragraph')}</p>
                </div>
                <div className="product_Window_Components">
                    <h3>{t('products_WindowComponents_title')}</h3>
                    <article><img src={windows} alt="Window Components" /></article>
                    <p>{t('products_WindowComponents_paragraph')}</p>
                </div>
                <div className="product_Pellet">
                    <a href="/pellet"><h3>{t('products_Pellet_title')}</h3></a>
                    <article><a href="/pellet"><img src={pellet} alt="Pellet" /></a></article>
                    <p>{t('products_Pellet_paragraph')}<a href="/pellet" id='product_Pellet_anchor'>{t('products_Pellet_anchor')}</a></p>
                </div>
                <div className="productDetail" id='tech_spec'>
                    <h3>{t('products_TechSpec_title')}</h3><br />
                    <div className="specs">
                        <div className="specsin"><h5>{t('products_TechSpec_h5_1')}</h5> <span>{t('products_TechSpec_span_1')}</span> </div>
                        <div className="specsin"><h5>{t('products_TechSpec_h5_2')}</h5> <span>{t('products_TechSpec_span_2')}</span> </div>
                        <div className="specsin"><h5>{t('products_TechSpec_h5_3')}</h5> <span>{t('products_TechSpec_span_3')}</span> </div>
                        <div className="specsin"><h5>{t('products_TechSpec_h5_4')}</h5> <span>{t('products_TechSpec_span_4')}</span> </div>
                        <div className="specsin"><h5>{t('products_TechSpec_h5_5')}</h5> <span>{t('products_TechSpec_span_5')}</span> </div>
                        <div className="specsin"><h5>{t('products_TechSpec_h5_6')}</h5> <span>{t('products_TechSpec_span_6')}</span></div>
                    </div>
                </div>
                <div className="productDetail" id='opt_spec'>
                    <h3>{t('products_OptSpec_title')}</h3><br />
                    <div className="specs">
                        <div className="specsin"><h5>{t('products_OptSpec_h5_1')}</h5> <span>{t('products_OptSpec_span_1')}</span> </div>
                        <div className="specsin"><h5>{t('products_OptSpec_h5_2')}</h5> <span>{t('products_OptSpec_span_2')}</span> </div>
                        <div className="specsin"><h5>{t('products_OptSpec_h5_3')}</h5> <span>{t('products_OptSpec_span_3')}</span> </div>
                        <div className="specsin"><h5>{t('products_OptSpec_h5_4')}</h5> <span>{t('products_OptSpec_span_4')}</span> </div>
                        <div className="specsin"><h5>{t('products_OptSpec_h5_5')}</h5> <span>{t('products_OptSpec_span_5')}</span> </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Products;