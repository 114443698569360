import React from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {

    const { t, i18n } = useTranslation();

    return (
        <footer id="foot">
            <div className="container footer__container">
                <div className="footer__head">
                    <div></div>
                </div>
                <div className="footMsj">
                    <div>
                        <span style={{ fontWeight: 'bolder', fontSize: '36px' }}>{t('footer_title')}</span>
                        <br />
                        <div style={{ marginLeft: '5%' }}>
                            <span style={{ marginRight: '2%', fontSize: '24px' }} className="footer__title">
                                {t('footer_email')}
                            </span>
                            {i18n.language === 'es' ? (
                                <a href="https://api.whatsapp.com/send?phone=56939266038" target="_blank" rel="noopener noreferrer" style={{ fontSize: '24px' }} className="footer__title footer_es">
                                    {t('footer_phone')}
                                </a>
                            ) : (
                                <span style={{ fontSize: '24px' }} className="footer__title">
                                    {t('footer_phone')}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className='footer_links_container'>
                    <ul className="footer__links">
                        <li><a href="#top">{t('footer_home')}</a></li>
                        <li><a href="#about">{t('footer_products')}</a></li>
                        <li><a href="#gallery">{t('footer_facilities')}</a></li>
                        <li><a href="#exhibitions">{t('footer_about_us')}</a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
